<template>
    <div class="card">
        <div class="bg-blue-light p-1">
            <TitleButton 
                :showBtn="false"
                title="Staff Register"
                btnTitle="Add New"
                :showAddNew="true"
                @onClickAddNewButton="openStaffRegisterAddModal"
            />
        </div>
        <div class="mt-2 px-2">
          <div class="row">
          <div class="col-12 col-sm-6 col-md-3 col-lg-3  mb-1">
            <input
                class="form-control invoice-edit-input  "
                tabindex="1"
                type="number"
                placeholder="Enter Staff Id...."
                v-model="staffId"
            >
          </div>
          <div class="col-12 col-sm-6 col-md-3 col-lg-3  mb-1">
            <input
                class="form-control invoice-edit-input  "
                tabindex="1"
                type="number"
                placeholder="Enter Generation Level..."
                v-model="generationLevel"
            >
          </div>
          <div class="col-12 col-sm-6 col-md-1 col-lg-1 mb-1">
          <button
              style="min-width: 64px;"
              @click="onClickToGo"
              class="btn btn-primary btn-block waves-effect waves-float waves-light"
          > Go
          </button>
          </div>
        </div>
        </div>
      <div class="col-12">
        <div class="d-flex justify-content-end px-2">
          <button
              @click="onClickToPrint"
              class="btn btn-outline-secondary btn-sm btn-download-invoice waves-effect mx-1"
          >
            Print
          </button>
        </div>
      </div>

        <div class="col-12 px-2 mb-4">
          <div class="card p-2" style="min-height: 200px">
            <div class="table-responsive">
              <table class="table table-bordered">
                <thead>
                <tr>
                  <th class="text-center">Staff ID</th>
                  <th class="text-center">Staff Name</th>
                  <th class="text-center">Parent Name & ID</th>
                  <th class="text-center">Generation</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, i) in tableItems" :key="i">
                  <td class="text-center">{{ item.serial_no }}</td>
                  <td class="text-center">{{ item.name }}</td>
                  <td class="text-center">{{ item.parent_name }} {{ item.parent_serial_no ? '(' + item.parent_serial_no + ')' : '' }}</td>
                  <td class="text-center">{{ item.level }}</td>
                </tr>
                </tbody>
              </table>
              <p v-if="tableItems.length < 0" class="text-center mt-5">No data</p>
            </div>
          </div>
        </div>

      <AddStaffRegisterModal
          v-if="store.state.isModalOpenTwo"
          :employees="employees"
      />

        <Loader v-if="loading"/>

    </div>
</template>

<script setup>
import TitleButton from '@/components/atom/TitleButton';
import { inject, onMounted, reactive, ref } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import Loader from '@/components/atom/LoaderComponent'
import handleSdm from "@/services/modules/sdm/sdm";
import { computed } from 'vue';
import handleCompany from "@/services/modules/company";
import staffRegisterPrinter from '@/services/utils/pdf/staffRegisterPrint';
import AddStaffRegisterModal from '@/components/molecule/company/sdm/AddStaffRegisterModal'
import handleContact from '@/services/modules/contact'

const store = useStore();
const route = useRoute();
const router = useRouter();
const showError = inject('showError');
const showSuccess = inject('showSuccess');
const loading = ref(false)
const tableItems = ref([]);
const staffRegisterData = ref([]);
const staffId = ref(null);
const generationLevel = ref(null);
const { fetchCompanyInfo } = handleCompany();
let companyInfo = reactive({})
const { exportToPDF } = staffRegisterPrinter();
const employees = reactive([]);
const { fetchContactProfiles } = handleContact()

const token = computed(() => localStorage.getItem('token'));

const {
  fetchStaffRegister
} = handleSdm()

const onClickToGo = () => {
  getStaffRegister();
}

const openStaffRegisterAddModal = () => {
  store.state.isModalOpenTwo = true
}
async function onClickToPrint() {
  let query = getQuery();

  await fetchStaffRegister(query).then((res) => {
    if (res.status) {
      staffRegisterData.value = res.data
    }
  }).then(() => {
    exportToPDF(companyInfo,staffRegisterData.value)
  }).catch((err) => {
    console.log(err)
    console.log("Something went wrong");
  });
}

const getStaffRegister = async () => {
    const query = getQuery();
    loading.value = true
    tableItems.value = []

    await fetchStaffRegister(query).then(res => {
        if (!res.status){
            showError(res.message)
            return
        }

        tableItems.value = res.data;
    }).catch(err => {
        console.log(err)
    }).finally(() => {
        loading.value = false
    })
}

const setCompanyInfo = async () => {
  await fetchCompanyInfo(route.params.companyId).then((res) => {
    companyInfo = res.data;
  }).catch((error) => {
    console.log(error);
  });
}

const getQuery = () => {
    let query = `?company_id=${route.params.companyId}`;
    if (staffId.value) query += '&staff_id=' + staffId.value;
    if (generationLevel.value) query += '&generation=' + generationLevel.value;

    return query;
}

onMounted(() => {
  const companyQuery = `?company_id=${route.params.companyId}`
  setCompanyInfo()
    Promise.all([
    fetchContactProfiles(companyQuery + `&type=employee`).then((res) => {
      if(res.status) employees.push(...res.data)
    })
  ])
});

</script>
